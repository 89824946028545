import React, { useRef, useEffect, useState } from 'react';
import { Box, Heading, Text, Flex, Stack } from '@chakra-ui/react';
import bondImage from '../../assets/images/bond.jpg';

const BondsSection: React.FC = () => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [textHeight, setTextHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (textContainerRef.current) {
      setTextHeight(textContainerRef.current.clientHeight);
    }
  }, [textContainerRef]);

  return (
    <Box w="100%" py="4em" px="2em" bg="black" color="white">
      <Box maxW="75em" mx="auto">
        {/* Título centrado */}
        <Heading as="h2" fontSize={{ base: '2em', md: '3em' }} mb="1em" textAlign="center" color="white">
          Creando Lazos Significativos
        </Heading>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          align="center"
          gap="2em"
        >
          {/* Imagen a la izquierda */}
          <Box flex="1" maxH={textHeight} overflow="hidden">
            <img
              src={bondImage}
              alt="Bond"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '0.9em',
                maxHeight: textHeight ? `${textHeight}px` : 'auto',
              }}
            />
          </Box>

          {/* Textos a la derecha */}
          <Box flex="1" ref={textContainerRef}>
            <Stack spacing="2em" fontSize="1em">
              {/* Cada bloque de texto en su propio Box gris */}
              <Box bg="gray.700" p="1.5em" borderRadius="0.9em">
                <Heading as="h3" fontSize="1.2em" mb="0.5em" color="white">
                  Mentorías Personalizadas
                </Heading>
                <Text color="white">
                  Miembros experimentados de la comunidad pueden convertirse en mentores del talento, ofreciendo orientación personalizada y valiosa.
                </Text>
              </Box>

              <Box bg="gray.700" p="1.5em" borderRadius="0.9em">
                <Heading as="h3" fontSize="1.2em" mb="0.5em" color="white">
                  Eventos Exclusivos
                </Heading>
                <Text color="white">
                  TokenMeet organiza encuentros y experiencias únicas donde talentos y comunidad pueden interactuar en persona, fortaleciendo sus vínculos.
                </Text>
              </Box>

              <Box bg="gray.700" p="1.5em" borderRadius="0.9em">
                <Heading as="h3" fontSize="1.2em" mb="0.5em" color="white">
                  Colaboraciones Creativas
                </Heading>
                <Text color="white">
                  Se fomentan proyectos colaborativos entre talentos y miembros de la comunidad, generando obras únicas y fortaleciendo relaciones.
                </Text>
              </Box>

              <Box bg="gray.700" p="1.5em" borderRadius="0.9em">
                <Heading as="h3" fontSize="1.2em" mb="0.5em" color="white">
                  Comunidad Digital Activa
                </Heading>
                <Text color="white">
                  Una plataforma en línea permite la interacción constante, compartiendo logros, desafíos y momentos especiales en tiempo real.
                </Text>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default BondsSection;
