import React from 'react';
import { Box, Flex, Link, Text, Image, VStack, Stack, SimpleGrid, Divider, IconButton } from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube, FaLinkedin, FaTiktok, FaFacebook } from 'react-icons/fa';
import logo from '../../assets/images/logo1.png';

const Footer: React.FC = () => {
  return (
    <Box as="footer" bg="gray.900" color="white" py={6} px={4}>
      <VStack spacing={6} align="center" maxW="1000px" mx="auto">
        {/* Logo Section */}
        <VStack spacing={2} align="center" mb={{ base: 4, md: 0 }}>
          <Image
            src={logo}
            alt="TokenMeet Logo"
            height="35px"
            objectFit="contain"
          />
          <Text fontSize="md" textAlign="center">
            Humanizamos la Tecnología
          </Text>
        </VStack>

        <Divider orientation="horizontal" borderColor="whiteAlpha.300" my={2} />

        {/* Navigation Links */}
        <Flex justify="center" wrap="wrap" mb={4} textAlign="center">
          <Link mx={2} href="#charity">Servicios</Link>
          <Link mx={2} href="#projects">Proyectos</Link>
          <Link mx={2} href="#events">Eventos</Link>
          <Link mx={2} href="#academy">Academy</Link>
          <Link mx={2} href="#charity">Solidaridad</Link>
          <Link mx={2} href="/tokenmeet-info">Comunidad</Link>
          <Link mx={2} href="/about-us">Quienes Somos</Link>
          <Link mx={2} href="/talentos">Proyecto Talentos</Link>
        </Flex>

        <Divider orientation="horizontal" borderColor="whiteAlpha.300" my={2} />

        {/* Social Media Links */}
        <Stack direction="row" spacing={4} justify="center">
          <IconButton
            as="a"
            href="https://www.instagram.com/token.meet/"
            aria-label="Instagram"
            icon={<FaInstagram />}
            variant="link"
            color="white"
            _hover={{ color: 'gray.400' }}
          />
          <IconButton
            as="a"
            href="https://x.com/tokenmeet"
            aria-label="Twitter"
            icon={<FaTwitter />}
            variant="link"
            color="white"
            _hover={{ color: 'gray.400' }}
          />
          <IconButton
            as="a"
            href="https://www.youtube.com/@TokenMeet"
            aria-label="YouTube"
            icon={<FaYoutube />}
            variant="link"
            color="white"
            _hover={{ color: 'gray.400' }}
          />
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/tokenmeet"
            aria-label="LinkedIn"
            icon={<FaLinkedin />}
            variant="link"
            color="white"
            _hover={{ color: 'gray.400' }}
          />
          <IconButton
            as="a"
            href="https://www.tiktok.com/@token.meet"
            aria-label="TikTok"
            icon={<FaTiktok />}
            variant="link"
            color="white"
            _hover={{ color: 'gray.400' }}
          />
          <IconButton
            as="a"
            href="https://www.facebook.com/profile.php?id=61564495966744"
            aria-label="Facebook"
            icon={<FaFacebook />}
            variant="link"
            color="white"
            _hover={{ color: 'gray.400' }}
          />
        </Stack>

        {/* Email */}
        <Text fontSize="sm" color="gray.400" textAlign="center" mb={2}>
          <Link href="mailto:info@token-meet.com" color="gray.500">info@token-meet.com</Link>
        </Text>

        <Divider orientation="horizontal" borderColor="whiteAlpha.300" my={2} />

        {/* Footer Links */}
        <Box textAlign="center">
          <Stack direction="row" spacing={4} justify="center">
            <Link href="/privacy-policy" color="white.500" _hover={{ textDecoration: 'underline' }}>
              Política de Privacidad
            </Link>
            <Link href="/terms-conditions" color="white.500" _hover={{ textDecoration: 'underline' }}>
              Términos y Condiciones
            </Link>
          </Stack>
          <Text fontSize="xs" color="gray.500" mt={2}>
            &copy; {new Date().getFullYear()} TokenMeet. Todos los derechos reservados.
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default Footer;
