import React, { useState } from 'react';
import {
  Box, FormControl, FormLabel, Input, Textarea, Heading, Text, Stack, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Flex, Image, Spinner, Alert, AlertIcon
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import GradientButton from '../buttons/GradientButton';
import athleteImage from '../../assets/images/atleta.jpg';
import artistImage from '../../assets/images/artista.jpg';
import axios from 'axios';

const TalentosSection: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formType, setFormType] = useState<'deportista' | 'artista'>('artista');
  const [isLoading, setIsLoading] = useState(false); 
  const [message, setMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    talent: '',
    socialLinks: ''
  });

  const navigate = useNavigate();

  const openForm = (type: 'deportista' | 'artista') => {
    setFormType(type);
    onOpen();
    setMessage(null); 
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); 

    try {
     
      const url = formType === 'artista' 
        ? 'https://tokenmeet.vercel.app/api/artistas' 
        : 'https://tokenmeet.vercel.app/api/deportistas';
      
        const data = {
          name: formData.name,
          email: formData.email,
          talent: formType === 'artista' ? formData.talent : undefined,
          sport: formType === 'deportista' ? formData.talent : undefined,  
          socialLinks: formData.socialLinks,
          type: formType === 'artista' ? 'artist' : 'athlete'  
        };
    
        const response = await axios.post(url, data);
        setMessage({ type: 'success', text: '¡Gracias por inscribirte! Nos pondremos en contacto contigo pronto.' });
      } catch (error: any) {
        if (error.response?.status === 400 && error.response.data.message.includes('El correo ya está registrado')) {
          setMessage({ type: 'error', text: 'Este correo ya está registrado. Recibirás más información por correo electrónico.' });
        } else {
          setMessage({ type: 'error', text: 'Hubo un problema al enviar el formulario. Inténtalo de nuevo.' });
        }
      } finally {
        setIsLoading(false);
      }
    };
    
    const title = formType === 'artista' ? 'Talento Artístico' : 'Talento Deportivo';
    const submitColor = formType === 'artista' ? '#ff79c6' : '#D4AF37';
  
    return (
      <Box w="100%" py={16} px={6} bg="black" color="white">
        <Box maxW="1200px" mx="auto" textAlign="center">
          <Heading as="h2" fontSize={{ base: '3xl', md: '5xl' }} mb={6} color="white">
            Apoyando Talento Emergente
          </Heading>
          <Text fontSize={{ base: 'lg', md: 'xl' }} mb={8} color="gray.300">
            En TokenMeet queremos conectar a los talentos emergentes, tanto deportistas como artistas, con nuestra comunidad.
            A través de la tokenización, ofrecemos una plataforma para apoyar y financiar el crecimiento de individuos con
            potencial único.
          </Text>
  
          {/* Sección para deportistas */}
          <Flex direction={{ base: 'column', md: 'row' }} align="center" mb={16} gap={10}>
            <Box flex="1" textAlign={{ base: 'center', md: 'left' }}>
              <Heading as="h3" fontSize={{ base: '2xl', md: '4xl' }} mb={4} color="#D4AF37">
                Talento Deportivo
              </Heading>
              <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.400" mb={6}>
                Los deportistas emergentes necesitan no solo oportunidades, sino apoyo para desarrollar sus habilidades
                y superar obstáculos. En TokenMeet, ayudamos a deportistas de todas las disciplinas a conectar con patrocinadores y una red de apoyo.
              </Text>
              <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                <GradientButton
                  gradientColor1="#D4AF37"
                  gradientColor2="#B8860B"
                  onClick={() => openForm('deportista')}
                >
                  Inscribirme como Deportista
                </GradientButton>
                <GradientButton
                  gradientColor1="#0ef1fa"
                  gradientColor2="#0b4d78"
                  onClick={() => navigate('/talentos')}
                >
                  Saber más
                </GradientButton>
              </Stack>
            </Box>
            <Image
              src={athleteImage}
              alt="Deportista"
              boxSize={{ base: '250px', md: '400px' }}
              objectFit="cover"
              borderRadius="15px"
            />
          </Flex>
  
          {/* Sección para artistas */}
          <Flex direction={{ base: 'column', md: 'row-reverse' }} align="center" mb={16} gap={10}>
            <Box flex="1" textAlign={{ base: 'center', md: 'left' }}>
              <Heading as="h3" fontSize={{ base: '2xl', md: '4xl' }} mb={4} color="#ff79c6">
                Talento Artístico
              </Heading>
              <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.400" mb={6}>
                Los artistas emergentes enfrentan un mercado competitivo. En TokenMeet, ofrecemos una plataforma para
                que los artistas presenten su trabajo y conecten con personas interesadas.
              </Text>
              <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                <GradientButton
                  gradientColor1="#ff79c6"
                  gradientColor2="#ff4081"
                  onClick={() => openForm('artista')}
                >
                  Inscribirme como Artista
                </GradientButton>
                <GradientButton
                  gradientColor1="#0ef1fa"
                  gradientColor2="#0b4d78"
                  onClick={() => navigate('/talentos')}
                >
                  Saber más
                </GradientButton>
              </Stack>
            </Box>
            <Image
              src={artistImage}
              alt="Artista"
              boxSize={{ base: '250px', md: '400px' }}
              objectFit="cover"
              borderRadius="15px"
            />
          </Flex>
  
          {/* Modal con el formulario directamente */}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="gray.900" color="white">
              <ModalCloseButton />
              <ModalBody>
                <Box textAlign="center" pt={8} pb={8}>
                  <Heading as="h2" fontSize="3xl" textAlign="center" mb={6} color={submitColor}>
                    {title}
                  </Heading>
  
                  {message && (
                    <Alert status={message.type} mb={4} bg={message.type === 'success' ? 'green.300' : 'red.300'}>
                      <AlertIcon />
                      <Text color="black">{message.text}</Text>
                    </Alert>
                  )}
  
                  {!message && (
                    <form onSubmit={handleSubmit}>
                      <FormControl mb={4} isRequired>
                        <FormLabel>Nombre Completo</FormLabel>
                        <Input
                          name="name"
                          placeholder="Ingresa tu nombre"
                          value={formData.name}
                          onChange={handleInputChange}
                          bg="gray.700"
                        />
                      </FormControl>
  
                      <FormControl mb={4} isRequired>
                        <FormLabel>Email</FormLabel>
                        <Input
                          name="email"
                          type="email"
                          placeholder="Ingresa tu email"
                          value={formData.email}
                          onChange={handleInputChange}
                          bg="gray.700"
                        />
                      </FormControl>
  
                      <FormControl mb={4} isRequired>
                        <FormLabel>{formType === 'artista' ? 'Talento Artístico' : 'Deporte'}</FormLabel>
                        <Input
                          name="talent"
                          placeholder={formType === 'artista' ? 'Describe tu talento' : 'Ingresa tu deporte'}
                          value={formData.talent}
                          onChange={handleInputChange}
                          bg="gray.700"
                        />
                      </FormControl>
  
                      <FormControl mb={4}>
                        <FormLabel>Redes Sociales</FormLabel>
                        <Textarea
                          name="socialLinks"
                          placeholder="Añade los enlaces de tus redes sociales"
                          value={formData.socialLinks}
                          onChange={handleInputChange}
                          bg="gray.700"
                        />
                      </FormControl>
  
                      <Stack direction="row" justify="center" mt={6}>
                        <GradientButton
                          gradientColor1={submitColor}
                          gradientColor2={submitColor === '#ff79c6' ? '#ff4081' : '#B8860B'}
                          type="submit"
                          isDisabled={isLoading}
                        >
                          {isLoading ? <Spinner /> : 'Inscribirme'}
                        </GradientButton>
                      </Stack>
                    </form>
                  )}
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
    );
  };
  
  export default TalentosSection;