import React from 'react';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';

const PowerSection: React.FC = () => {
  return (
    <Box w="100%" py="4em" px="2em" bg="black" color="white" textAlign="center">
      <Box maxW="75em" mx="auto">
        {/* Título principal */}
        <Heading as="h2" fontSize={{ base: '2em', md: '3em' }} mb="1em" color="white">
          El Poder de la Comunidad en el Desarrollo del Talento
        </Heading>

        {/* Contenedor de las columnas */}
        <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center" gap="2em">
          
          {/* Columna 1 */}
          <Box flex="1" bg="gray.800" p="2em" borderRadius="0.9em">
            <Heading as="h3" fontSize="1.8em" mb="1em">
              Apoyo Económico
            </Heading>
            <Text fontSize="1.2em" color="gray.300">
              La tokenización permite a la comunidad proporcionar recursos financieros directamente al talento emergente, 
              eliminando barreras económicas para su desarrollo.
            </Text>
          </Box>

          {/* Columna 2 */}
          <Box flex="1" bg="gray.800" p="2em" borderRadius="0.9em">
            <Heading as="h3" fontSize="1.8em" mb="1em">
              Respaldo Moral
            </Heading>
            <Text fontSize="1.2em" color="gray.300">
              Más allá del dinero, la comunidad ofrece un apoyo emocional invaluable, motivando al talento a perseverar 
              en los momentos difíciles.
            </Text>
          </Box>

          {/* Columna 3 */}
          <Box flex="1" bg="gray.800" p="2em" borderRadius="0.9em">
            <Heading as="h3" fontSize="1.8em" mb="1em">
              Participación Activa
            </Heading>
            <Text fontSize="1.2em" color="gray.300">
              Los miembros de la comunidad pueden involucrarse en decisiones clave, contribuyendo con su experiencia y 
              perspectivas únicas al desarrollo del talento.
            </Text>
          </Box>

        </Flex>
      </Box>
    </Box>
  );
};

export default PowerSection;
