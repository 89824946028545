import React from 'react';
import { Box, Heading, Text, Flex, VStack, Grid, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import heroImage from '../assets/images/Hero2.png';
import ScrollToTop from '../components/ScrollToTop';  // Asegúrate de importar esto

const TokenMeetInfoPage: React.FC = () => {
  return (
    <>
      <ScrollToTop />  {/* Esto garantiza que la página comienza desde arriba al cargar */}
      
      {/* Hero Section */}
      <Box
  position="relative"
  height="100vh"
  overflow="hidden"
  display="flex"
  alignItems="center"
  justifyContent="center"
  bgImage={`url(${heroImage})`}
  bgPosition="center"
  bgSize="cover"
>
  {/* Overlay */}
  <Box
    position="absolute"
    top="0"
    left="0"
    width="100%"
    height="100%"
    bg="rgba(0, 0, 0, 0.5)" // Fondo oscuro semitransparente para mejorar el contraste
    zIndex="1"
  />

  {/* Content */}
  <Box
    position="relative"
    zIndex="2"
    textAlign="center"
    color="white"
    px={{ base: "6", md: "12" }}
    py={{ base: "8", md: "16" }}
    maxWidth="container.lg"
  >
    <Heading
      as="h1"
      fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
      fontWeight="bold"
      mb="4"
      textShadow="1px 1px 4px rgba(0, 0, 0, 0.7)"
    >
      Gamificación de la Comunidad TokenMeet
    </Heading>
    <Text
      fontSize={{ base: "md", md: "lg", lg: "xl" }}
      mb="6"
      textShadow="1px 1px 4px rgba(0, 0, 0, 0.5)"
    >
      Bienvenidos a la revolucionaria comunidad de TokenMeet, donde la innovación blockchain se encuentra con la gamificación. Nuestro programa único de niveles de socios está diseñado para incentivar la participación activa, fomentar la inversión y crear una red vibrante de entusiastas y profesionales de la tecnología blockchain. Desde exploradores curiosos hasta mentores experimentados, cada nivel ofrece beneficios exclusivos y oportunidades de crecimiento.
    </Text>
  </Box>
</Box>


      {/* Levels Section */}
      <Box maxW="7xl" mx="auto" py={10} px={4}>
        {/* Nivel Explorador */}
        <Box mb={10} p={6} borderRadius="lg" bg="gray.100">
          <Heading as="h2" fontSize="2xl" mb={4} textAlign="center">
            Nivel Explorador: El comienzo de tu viaje blockchain
          </Heading>
          <VStack spacing={6} align="start">
            <Flex align="start">
              <Text fontSize="2xl" fontWeight="bold" color="teal.500" mr={4}>1</Text>
              <Box>
                <Text fontWeight="bold">Registro fácil</Text>
                <Text>Únete a nuestra comunidad privada con un simple proceso de registro, abriendo las puertas a un mundo de oportunidades en blockchain.</Text>
              </Box>
            </Flex>
            <Flex align="start">
              <Text fontSize="2xl" fontWeight="bold" color="teal.500" mr={4}>2</Text>
              <Box>
                <Text fontWeight="bold">Recursos educativos básicos</Text>
                <Text>Accede a una biblioteca curada de materiales introductorios para construir una base sólida en tecnología blockchain y criptomonedas.</Text>
              </Box>
            </Flex>
            <Flex align="start">
              <Text fontSize="2xl" fontWeight="bold" color="teal.500" mr={4}>3</Text>
              <Box>
                <Text fontWeight="bold">Certificado de participación</Text>
                <Text>Obtén reconocimiento oficial por tu compromiso con el aprendizaje y la participación en la comunidad TokenMeet.</Text>
              </Box>
            </Flex>
            <Flex align="start">
              <Text fontSize="2xl" fontWeight="bold" color="teal.500" mr={4}>4</Text>
              <Box>
                <Text fontWeight="bold">Webinars introductorios</Text>
                <Text>Participa en sesiones en vivo exclusivas diseñadas para familiarizarte con los conceptos clave y las últimas tendencias en el espacio blockchain.</Text>
              </Box>
            </Flex>
          </VStack>
        </Box>

        {/* Nivel Junior */}
        <Box mb={10} p={6} borderRadius="lg" bg="gray.100">
          <Heading as="h2" fontSize="2xl" mb={4} textAlign="center">
            Ascendiendo al Nivel Junior: tus primeros pasos como inversor
          </Heading>
          <VStack spacing={6} align="start">
            <Flex align="start">
              <Text fontSize="2xl" fontWeight="bold" color="teal.500" mr={4}>1</Text>
              <Box>
                <Text fontWeight="bold">Inversión inicial</Text>
                <Text>Da el salto y realiza tu primera inversión en un proyecto blockchain a través de TokenMeet, abriendo la puerta a beneficios exclusivos y oportunidades de crecimiento.</Text>
              </Box>
            </Flex>
            <Flex align="start">
              <Text fontSize="2xl" fontWeight="bold" color="teal.500" mr={4}>2</Text>
              <Box>
                <Text fontWeight="bold">Proyección de proyectos</Text>
                <Text>Aprovecha la plataforma TokenMeet para dar visibilidad a tu propio proyecto, aunque sea solo de forma publicitaria, aumentando tu exposición en la comunidad.</Text>
              </Box>
            </Flex>
            <Flex align="start">
              <Text fontSize="2xl" fontWeight="bold" color="teal.500" mr={4}>3</Text>
              <Box>
                <Text fontWeight="bold">Descuentos especiales</Text>
                <Text>Disfruta de precios reducidos en eventos y seminarios, permitiéndote profundizar en tu educación blockchain sin romper el banco.</Text>
              </Box>
            </Flex>
            <Flex align="start">
              <Text fontSize="2xl" fontWeight="bold" color="teal.500" mr={4}>4</Text>
              <Box>
                <Text fontWeight="bold">Consultoría gratuita</Text>
                <Text>Recibe asesoramiento inicial para guiar tus decisiones de inversión y desarrollo de proyectos, acelerando tu curva de aprendizaje.</Text>
              </Box>
            </Flex>
          </VStack>
        </Box>

        {/* Nivel Senior */}
        <Box mb={10} p={6} borderRadius="lg" bg="gray.100">
          <Heading as="h2" fontSize="2xl" mb={4} textAlign="center">
            Nivel Senior: Consolidando tu posición en el ecosistema
          </Heading>
          <VStack spacing={6} align="start">
            <Box>
              <Text fontWeight="bold">Inversión avanzada</Text>
              <Text>Como Senior, no solo inviertes en proyectos, sino que también tienes oportunidad de realizar donaciones solidarias, demostrando tu compromiso con el impacto social del blockchain.</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Educación intermedia</Text>
              <Text>Accede a recursos educativos de nivel intermedio, participando activamente en mentorías y formaciones avanzadas que elevarán tus habilidades y conocimientos.</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Oportunidades de presentación</Text>
              <Text>Presenta tu proyecto en TokenMeet, ganando visibilidad y feedback valioso de la comunidad y expertos del sector.</Text>
            </Box>
          </VStack>
        </Box>

        {/* Nivel Oro */}
        <Box mb={10} p={6} borderRadius="lg" bg="gray.100">
          <Heading as="h2" fontSize="2xl" mb={4} textAlign="center">
            Alcanzando el Oro: Beneficios Premium para Inversores dedicados
          </Heading>
          <VStack spacing={6} align="start">
            <Box>
              <Text fontWeight="bold">Inversión Múltiple</Text>
              <Text>Invierte en dos o más proyectos para alcanzar el estatus Oro, demostrando tu compromiso y diversificando tu portafolio en blockchain.</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Proyección Amplificada</Text>
              <Text>Obtén la capacidad de proyectar 2 o más proyectos a través de TokenMeet, maximizando tu influencia y oportunidades de crecimiento en la comunidad.</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Acceso VIP</Text>
              <Text>Disfruta de acceso preferencial a eventos exclusivos, networking de alto nivel y oportunidades únicas reservadas para miembros Oro.</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Descuentos Tecnológicos</Text>
              <Text>Aprovecha descuentos significativos en servicios tecnológicos, impulsando el desarrollo de tus proyectos blockchain con herramientas de vanguardia.</Text>
            </Box>
          </VStack>
        </Box>

        {/* Nivel Platino */}
        <Box mb={10} p={6} borderRadius="lg" bg="gray.100">
          <Heading as="h2" fontSize="2xl" mb={4} textAlign="center">
            Platino: La Cúspide del Liderazgo en TokenMeet
          </Heading>
          <VStack spacing={6} align="start">
            <Box>
              <Text fontWeight="bold">Mentor de Élite</Text>
              <Text>Conviértete en un mentor respetado dentro de la comunidad, compartiendo tu experiencia y guiando a la próxima generación de líderes blockchain.</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Inversión Estratégica</Text>
              <Text>Participa en múltiples proyectos de alto potencial, modelando el futuro del ecosistema blockchain con tus inversiones informadas.</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Beneficios Exclusivos</Text>
              <Text>Accede a descuentos sin precedentes y ofertas personalizadas en servicios y eventos premium de TokenMeet.</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Voz Influyente</Text>
              <Text>Participa como ponente destacado en conferencias, webinars y eventos de alto perfil, estableciéndote como un líder de pensamiento en la industria.</Text>
            </Box>
          </VStack>
        </Box>

        {/* Impacto Social */}
        <Box mb={10} p={6} borderRadius="lg" bg="gray.100">
  <Heading as="h2" fontSize="2xl" mb={4} textAlign="center">
    Comparación de Beneficios por Nivel
  </Heading>
  <Table variant="striped" colorScheme="teal" borderWidth="1px" borderColor="teal.200">
    <Thead>
      <Tr>
        <Th bg="teal.600" color="white" fontWeight="bold" borderColor="teal.700" borderWidth="1px">Beneficio</Th>
        <Th bg="teal.600" color="white" fontWeight="bold" borderColor="teal.700" borderWidth="1px">Explorador</Th>
        <Th bg="teal.600" color="white" fontWeight="bold" borderColor="teal.700" borderWidth="1px">Junior</Th>
        <Th bg="teal.600" color="white" fontWeight="bold" borderColor="teal.700" borderWidth="1px">Senior</Th>
        <Th bg="teal.600" color="white" fontWeight="bold" borderColor="teal.700" borderWidth="1px">Oro</Th>
        <Th bg="teal.600" color="white" fontWeight="bold" borderColor="teal.700" borderWidth="1px">Platino</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td fontWeight="semibold" borderColor="teal.200" borderWidth="1px">Acceso a Recursos Educativos</Td>
        <Td borderColor="teal.200" borderWidth="1px">Básico</Td>
        <Td borderColor="teal.200" borderWidth="1px">Básico</Td>
        <Td borderColor="teal.200" borderWidth="1px">Intermedio</Td>
        <Td borderColor="teal.200" borderWidth="1px">Avanzado</Td>
        <Td borderColor="teal.200" borderWidth="1px">Élite</Td>
      </Tr>
      <Tr>
        <Td fontWeight="semibold" borderColor="teal.200" borderWidth="1px">Inversión en Proyectos</Td>
        <Td borderColor="teal.200" borderWidth="1px">-</Td>
        <Td borderColor="teal.200" borderWidth="1px">1 Proyecto</Td>
        <Td borderColor="teal.200" borderWidth="1px">1 Proyecto</Td>
        <Td borderColor="teal.200" borderWidth="1px">2+ Proyectos</Td>
        <Td borderColor="teal.200" borderWidth="1px">2+ Proyectos</Td>
      </Tr>
      <Tr>
        <Td fontWeight="semibold" borderColor="teal.200" borderWidth="1px">Proyección de Proyectos</Td>
        <Td borderColor="teal.200" borderWidth="1px">-</Td>
        <Td borderColor="teal.200" borderWidth="1px">Publicitaria</Td>
        <Td borderColor="teal.200" borderWidth="1px">1 Proyecto</Td>
        <Td borderColor="teal.200" borderWidth="1px">2+ Proyectos</Td>
        <Td borderColor="teal.200" borderWidth="1px">2+ Proyectos</Td>
      </Tr>
      <Tr>
        <Td fontWeight="semibold" borderColor="teal.200" borderWidth="1px">Acceso a Eventos</Td>
        <Td borderColor="teal.200" borderWidth="1px">Webinars Intro</Td>
        <Td borderColor="teal.200" borderWidth="1px">Descuentos</Td>
        <Td borderColor="teal.200" borderWidth="1px">Prioridad</Td>
        <Td borderColor="teal.200" borderWidth="1px">VIP</Td>
        <Td borderColor="teal.200" borderWidth="1px">VIP + Ponente</Td>
      </Tr>
      <Tr>
        <Td fontWeight="semibold" borderColor="teal.200" borderWidth="1px">Consultoría</Td>
        <Td borderColor="teal.200" borderWidth="1px">-</Td>
        <Td borderColor="teal.200" borderWidth="1px">Inicial Gratis</Td>
        <Td borderColor="teal.200" borderWidth="1px">Descuentos</Td>
        <Td borderColor="teal.200" borderWidth="1px">Avanzada</Td>
        <Td borderColor="teal.200" borderWidth="1px">Personalizada</Td>
      </Tr>
      <Tr>
        <Td fontWeight="semibold" borderColor="teal.200" borderWidth="1px">Red de Contactos</Td>
        <Td borderColor="teal.200" borderWidth="1px">Básica</Td>
        <Td borderColor="teal.200" borderWidth="1px">Ampliada</Td>
        <Td borderColor="teal.200" borderWidth="1px">Extensa</Td>
        <Td borderColor="teal.200" borderWidth="1px">Exclusiva</Td>
        <Td borderColor="teal.200" borderWidth="1px">Élite Global</Td>
      </Tr>
    </Tbody>
  </Table>
</Box>
      </Box>

    </>
  );
};

export default TokenMeetInfoPage;
