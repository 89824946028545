import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Box, Spinner, Flex } from '@chakra-ui/react';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import ProjectsPage from './pages/ProjectsPage';
import HomePage from './pages/HomePage';
import AllEventsPage from './pages/AllEventsPage';
import EventDetailPage from './pages/EventDetailPage';
import AcademyPage from './pages/AcademyPage';
import TokenMeetInfoPage from './pages/TokenMeetInfoPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditions from './pages/TermsAndConditions';
import AboutUsPage from './pages/AboutUsPage';
import TalentosPage from './pages/TalentosProgamPage'; // Asegúrate de importar el nuevo componente
import { fetchProjectsConfig } from './config/projectsConfig';
import { Project } from './types/projectTypes';
import ScrollToTop from './components/ScrollToTop'; // Asegúrate de que la ruta sea correcta
import TalentosProgramPage from './pages/TalentosProgamPage';

function App() {
  const [projectsConfig, setProjectsConfig] = useState<{
    activeProjects: Project[];
    upcomingProjects: Project[];
    studyProjects: Project[];
  } | null>(null);

  const [loading, setLoading] = useState(true);
  const location = useLocation(); // Para detectar cambios en la ruta

  // Cargar projectsConfig al montar la app
  useEffect(() => {
    const loadProjects = async () => {
      try {
        const config = await fetchProjectsConfig();
        setProjectsConfig(config);
      } catch (error) {
        console.error('Error fetching project config:', error);
      } finally {
        setLoading(false);
      }
    };
    loadProjects();
  }, []);

  // Activar el estado de carga cada vez que cambia la ruta
  useEffect(() => {
    setLoading(true);  // Iniciar el estado de carga cada vez que cambia la ruta

    const timer = setTimeout(() => {
      setLoading(false);  // Simular un tiempo de carga después de 1 segundo
    }, 1000);

    return () => clearTimeout(timer);  // Limpiamos el timeout al desmontar
  }, [location.pathname]);

  if (loading || !projectsConfig) {
    return (
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        bg="white"
      >
        <Spinner size="xl" color="blue.500" />
        <Box mt={4} fontSize="lg" color="gray.500">
          Cargando...
        </Box>
      </Flex>
    );
  }

  return (
    <>
      <Navbar />
      <Routes>
        <Route 
          path="/" 
          element={<HomePage projectsConfig={projectsConfig} />} 
        />
        <Route 
          path="/projects" 
          element={<ProjectsPage projectsConfig={projectsConfig} />} 
        />
        <Route path="/events" element={<AllEventsPage />} />
        <Route path="/events/:eventId" element={<EventDetailPage />} />
        <Route path="/academy" element={<AcademyPage />} />
        <Route path="/tokenmeet-info" element={<TokenMeetInfoPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route 
          path="/servicios" 
          element={<HomePage projectsConfig={projectsConfig} />} 
        />
        {/* Nueva ruta para TalentosPage */}
        <Route path="/talentos" element={<TalentosProgramPage />} />
      </Routes>
      <Footer />
    </>
  );
}


const AppWrapper = () => (
  <Router>
    <ScrollToTop /> {/* Asegúrate de que esto esté fuera de Routes */}
    <App />
  </Router>
);

export default AppWrapper;
