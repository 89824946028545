import React from 'react';
import { Box, Heading, Text, Flex, Image } from '@chakra-ui/react';
import communityImage from '../../assets/images/community_support.jpg';  // Reemplaza con la imagen correcta

const CommunitySupportSection: React.FC = () => {
  return (
    <Box w="100%" h="100%" bg="black" color="white" display="flex" alignItems="center" py="4em" px="2em">
      <Box maxW="75em" mx="auto" w="100%">
        <Flex direction={{ base: 'column', md: 'row' }} align="center" h="100%">
          {/* Texto a la izquierda */}
          <Box flex="1" pr={{ base: 0, md: '3em' }} mb={{ base: '2em', md: 0 }} textAlign={{ base: 'center', md: 'left' }}>
            <Heading as="h2" fontSize={{ base: '2em', md: '3em' }} mb="1.5em">
              El Poder de la Comunidad en el Desarrollo del Talento
            </Heading>
            <Text fontSize={{ base: '1.2em', md: '1.4em' }} color="gray.300">
              La tokenización permite a la comunidad proporcionar recursos financieros directamente al talento emergente,
              eliminando barreras económicas para su desarrollo. Más allá del dinero, la comunidad ofrece un apoyo emocional
              invaluable, motivando al talento a perseverar en los momentos difíciles. Además, los miembros de la comunidad
              pueden involucrarse en decisiones clave, contribuyendo con su experiencia y perspectivas únicas al desarrollo
              del talento.
            </Text>
          </Box>

          {/* Imagen a la derecha */}
          <Box flex="1" textAlign="center">
            <Image
              src={communityImage}
              alt="Apoyo de la comunidad"
              boxSize={{ base: 'auto', md: 'auto' }}  // Ajustado a em
              objectFit="cover"
              borderRadius="0.9em"
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default CommunitySupportSection;
