import React from 'react';
import CommunitySupportSection from '../components/TalentosProgram/CommunitySupportSection';
import PowerSection from '../components/TalentosProgram/PowerSection';
import MutualBenefitSection from '../components/TalentosProgram/MutualBenefitsSection';
import CreativeProcessSection from '../components/TalentosProgram/CreativeProcessSection';
import BondsSection from '../components/TalentosProgram/BondsSection';
import TechnologySection from '../components/TalentosProgram/TechnologySection';
import IndustryImpactSection from '../components/TalentosProgram/IndustryImpactSection';
import JoinRevolutionSection from '../components/TalentosProgram/JoinRevolutionSection';
import TalentosSection from '../components/TalentosProgram/TalentosForm';

const TalentosProgramPage: React.FC = () => {
  return (
    <div>
      <CommunitySupportSection />
      <TalentosSection/>
      <PowerSection />
      <MutualBenefitSection />
      <CreativeProcessSection />
      <BondsSection />
      <TechnologySection />
      <IndustryImpactSection />
      <JoinRevolutionSection />
    </div>
  );
};

export default TalentosProgramPage;
