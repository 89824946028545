import React, { useState } from 'react';
import { Box, Heading, Grid, Select } from '@chakra-ui/react';
import { educationResourcesConfig } from '../config/educationResourcesConfig';
import NewsCard from '../components/cards/NewsCard';

const AcademyPage: React.FC = () => {
  const [filter, setFilter] = useState<string>('all');

  // Filtra los recursos según la categoría seleccionada
  const filteredResources = educationResourcesConfig.filter(resource =>
    filter === 'all' || resource.category === filter
  );

  // Obtén las categorías únicas para el filtro
  const categories = Array.from(new Set(educationResourcesConfig.map(resource => resource.category)));

  // Filtra los recursos por tipo de contenido
  const latestNews = filteredResources.filter(resource => resource.type === 'news');
  const latestVideos = filteredResources.filter(resource => resource.type === 'video');

  // Función para obtener el ID del vídeo de la URL
  const getVideoId = (url: string) => {
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=))([^"&?\/\s]{11})/);
    return match ? match[1] : null;
  };

  return (
    <Box maxW="1200px" mx="auto" py={10} px={4} mt={20}>
      <Heading as="h2" mb={8} textAlign="center" fontSize="3xl">
        Recursos para nuestra comunidad
      </Heading>

      <Select mb={8} value={filter} onChange={(e) => setFilter(e.target.value)}>
        <option value="all">All Categories</option>
        {categories.map(category => (
          <option key={category} value={category}>{category}</option>
        ))}
      </Select>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
        {latestVideos.map(resource => {
          const videoId = getVideoId(resource.videoUrl ?? '');
          return (
            <Box
              key={resource.id}
              position="relative"
              paddingBottom="56.25%"  // Mantiene la proporción 16:9
              height="0"
              overflow="hidden"
            >
              {videoId ? (
                <Box
                  as="iframe"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title={resource.title}
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              ) : (
                <Box textAlign="center" color="red.500">Invalid video URL</Box>
              )}
            </Box>
          );
        })}
      </Grid>

      
    </Box>
  );
};

export default AcademyPage;
