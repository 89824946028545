import React, { useState } from 'react';
import { Box, Image, Heading, Text, Flex } from '@chakra-ui/react';
import { Project } from '../../types/projectTypes';
import ProgressBar from '../../components/progressbars/ProgressBar';
import Lottie from 'lottie-react';
import pushAnimation from '../../assets/push.json';

const ProjectCard: React.FC<{ project: Project }> = ({ project }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Función para manejar la expansión del texto
  const handleToggleExpand = (event: React.MouseEvent) => {
    event.stopPropagation();  // Evitar que el clic se propague a la tarjeta
    setIsExpanded(!isExpanded);
  };

  const handleCardClick = () => {
    const userConfirmed = window.confirm("Vas a salir de Tokenmeet y ser redirigido a la web3 del proyecto. Una vez que accedas, toda la información es responsabilidad del propietario de la web. ¿Deseas continuar?");
    if (userConfirmed) {
      window.open(project.projectUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Box
      borderRadius="8px"
      overflow="hidden"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      bg="white"
      transition="all 0.3s ease"
      _hover={{ transform: 'translateY(-5px)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}
      maxW="100%"
      m="0 auto"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      mb="40px"
      position="relative"
      cursor="pointer"
      onClick={handleCardClick}
      pb="24px"  // Agrega un padding bottom más grande para mayor espacio
    >
      <Box position="relative">
        <Image
          src={project.image}
          alt={project.title}
          objectFit="cover"
          height="200px"
          width="100%"
          borderTopRadius="8px"
        />
        <Box
          position="absolute"
          bottom="0"
          width="100%"
          bgGradient="linear(to-r, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5))"
          color="white"
          textAlign="left"
          pl="24px"
          py="4px"
        >
          <Text fontSize="0.875rem" fontWeight="bold">
            {project.category}
          </Text>
        </Box>
      </Box>
      <Box p="24px" flexGrow={1} display="flex" flexDirection="column" position="relative" zIndex="1">
        <Heading fontSize="1.25rem" mb="8px" color="#0b4d78">
          {project.title}
        </Heading>
        <Text fontSize="0.875rem" color="#0b4d78" mb="8px">
          Emisor: {project.promoter}
        </Text>
        <Box
          display="flex"
          flexDirection="column"
          mb="16px"
        >
          <Box
            maxHeight={isExpanded ? 'none' : '4.5rem'}  // Ajusta la altura según sea necesario
            overflow="hidden"
            mb="8px"
          >
            <Text textAlign="left">
              {project.description}
            </Text>
          </Box>
          <Flex justify="space-between" align="center">
            <Text fontSize="0.875rem" color="#0b4d78" fontWeight="bold">
              Haz clic para acceder al proyecto
            </Text>
            <Text
              fontSize="0.875rem"
              color="primary.500"
              cursor="pointer"
              onClick={handleToggleExpand}
              zIndex="2"
            >
              {isExpanded ? 'Mostrar menos' : 'Mostrar más'}
            </Text>
          </Flex>
        </Box>
        {project.isActive && project.tokensIssued !== undefined && (
          <Box mt="auto" width="80%" alignSelf="flex-start">
            {/*<ProgressBar tokensIssued={project.tokensIssued} totalTokens={project.totalTokens} />*/}
          </Box>
        )}
      </Box>
      <Box
        position="absolute"
        bottom="4%"
        right="2%"
        width="50px"
        height="50px"
      >
        <Lottie animationData={pushAnimation} loop={true} />
      </Box>
    </Box>
  );
};

export default ProjectCard;
