import React from 'react';
import { Box, Heading, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';

const JoinRevolutionSection: React.FC = () => {
  return (
    <Box w="100%" py="4em" px="2em" bg="black" color="white" textAlign="center">
      <Box maxW="75em" mx="auto">
        {/* Título principal */}
        <Heading as="h2" fontSize={{ base: '2em', md: '3em' }} mb="1em" color="white" textAlign="center">
          Únete a la Revolución del Talento con TokenMeet
        </Heading>

        {/* Tabla centrada y estilizada */}
        <TableContainer mb="2em" w={{ base: '100%', md: '80%' }} mx="auto">
          <Table variant="simple" size="lg" textAlign="center" border="1px" borderColor="white">
            <Thead>
              <Tr bg="gray.900" borderBottom="2px solid white">
                <Th color="white" fontSize="1.2em" textAlign="center" borderRight="1px solid white">Para Talentos Emergentes</Th>
                <Th color="white" fontSize="1.2em" textAlign="center">Para Inversores y Seguidores</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr borderBottom="1px solid #1a1a1a">
                <Td color="white" textAlign="center" borderRight="1px solid white">Acceso a financiación y apoyo comunitario</Td>
                <Td color="white" textAlign="center">Oportunidad de invertir en talentos prometedores</Td>
              </Tr>
              <Tr borderBottom="1px solid #1a1a1a">
                <Td color="white" textAlign="center" borderRight="1px solid white">Orientación profesional y mentoría</Td>
                <Td color="white" textAlign="center">Participación en el proceso creativo</Td>
              </Tr>
              <Tr borderBottom="1px solid #1a1a1a">
                <Td color="white" textAlign="center" borderRight="1px solid white">Exposición global y networking</Td>
                <Td color="white" textAlign="center">Potenciales retornos financieros</Td>
              </Tr>
              <Tr>
                <Td color="white" textAlign="center" borderRight="1px solid white">Libertad creativa y recursos para crecer</Td>
                <Td color="white" textAlign="center">Conexiones significativas con creadores</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        {/* Texto debajo */}
        <Text fontSize="1.2em" color="gray.300" w={{ base: '100%', md: '80%' }} mx="auto" textAlign="center">
          TokenMeet está transformando la manera en que apoyamos y desarrollamos el talento. Ya seas un artista buscando 
          el impulso para tu carrera o un entusiasta deseando ser parte del próximo gran éxito, nuestra plataforma te 
          ofrece la oportunidad de ser parte de algo extraordinario. Únete a nosotros hoy y sé parte de la revolución 
          que está redefiniendo el futuro del talento y la creatividad.
        </Text>
      </Box>
    </Box>
  );
};

export default JoinRevolutionSection;
