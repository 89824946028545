import React from 'react';
import { Box, Heading, Grid, useMediaQuery } from '@chakra-ui/react';
import EventCard from '../../components/cards/EventCard';
import { eventsConfig } from '../../config/eventsConfig';
import GradientButton from '../../components/buttons/GradientButton';

const UpcomingEventsSection: React.FC = () => {
  const upcomingEvents = eventsConfig.slice(0, 3); // Obtiene los próximos 3 eventos

  // Usa useMediaQuery para aplicar estilos específicos para pantallas grandes
  const [isLaptopOrLarger] = useMediaQuery("(min-width: 992px)");

  return (
    <Box maxW="1200px" mx="auto" py={10} px={4} textAlign="center">
      <Heading 
        as="h2" 
        fontSize={isLaptopOrLarger ? "5xl" : "3xl"} // Ajusta el tamaño del título
        mb={isLaptopOrLarger ? 10 : 6} // Ajusta el margen inferior
        color="primary.600"
        paddingTop={isLaptopOrLarger ? "0" : "10px"} // Agrega espacio superior en pantallas más pequeñas
      >
        Próximos Eventos
      </Heading>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
        {upcomingEvents.map((event) => (
          <EventCard key={event.id} event={event} />
        ))}
      </Grid>

      {/* Botones de Ver Todo y Ver Proyectos */}
      <Box display="flex" justifyContent="center" mt={10} gap={4}>
        <GradientButton onClick={() => window.open('/events', '_blank')}  gradientColor1="#0ef1fa"
              gradientColor2="#0b4d78">
          Ver Todo
        </GradientButton>
        <GradientButton onClick={() => window.open('/projects', '_blank')}  gradientColor1="#0ef1fa"
              gradientColor2="#0b4d78">
          Ver Proyectos
        </GradientButton>
      </Box>
    </Box>
  );
};

export default UpcomingEventsSection;
