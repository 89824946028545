import React, { useRef, useEffect, useState } from 'react';
import { Box, Heading, Text, Flex, Stack } from '@chakra-ui/react';
import industryImage from '../../assets/images/industry-impact.jpg'; 

const IndustryImpactSection: React.FC = () => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [textHeight, setTextHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (textContainerRef.current) {
      setTextHeight(textContainerRef.current.clientHeight);
    }
  }, [textContainerRef]);

  return (
    <Box w="100%" py="4em" px="2em" bg="black" color="white">
      <Box maxW="75em" mx="auto">
        {/* Título centrado */}
        <Heading as="h2" fontSize={{ base: '2em', md: '3em' }} mb="1em" textAlign="center" color="white">
          Impacto en la Industria Creativa
        </Heading>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          align="center"
          gap="2em"
        >
          {/* Imagen a la izquierda */}
          <Box flex="1" maxH={textHeight} overflow="hidden">
            <img
              src={industryImage}
              alt="Industry Impact"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '0.9em',
                maxHeight: textHeight ? `${textHeight}px` : 'auto',
              }}
            />
          </Box>

          {/* Textos numerados a la derecha */}
          <Box flex="1" ref={textContainerRef}>
            <Stack spacing="2em" fontSize="1em">
              {/* Cada bloque de texto en su propio Stack numerado */}
              <Box p="1.5em" borderRadius="0.9em">
                <Heading as="h3" fontSize="1.2em" mb="0.5em" color="white">
                  1. Democratización del Talento
                </Heading>
                <Text color="gray.300">
                  TokenMeet elimina barreras tradicionales, permitiendo que talentos de diversos orígenes reciban el apoyo necesario para destacar.
                </Text>
              </Box>

              <Box p="1.5em" borderRadius="0.9em">
                <Heading as="h3" fontSize="1.2em" mb="0.5em" color="white">
                  2. Nuevos Modelos de Financiación
                </Heading>
                <Text color="gray.300">
                  La tokenización introduce formas innovadoras de financiar proyectos creativos, reduciendo la dependencia de grandes instituciones.
                </Text>
              </Box>

              <Box p="1.5em" borderRadius="0.9em">
                <Heading as="h3" fontSize="1.2em" mb="0.5em" color="white">
                  3. Comunidades Globales
                </Heading>
                <Text color="gray.300">
                  Se crean conexiones internacionales entre talentos y seguidores, enriqueciendo la diversidad cultural en las industrias creativas.
                </Text>
              </Box>

              <Box p="1.5em" borderRadius="0.9em">
                <Heading as="h3" fontSize="1.2em" mb="0.5em" color="white">
                  4. Innovación Acelerada
                </Heading>
                <Text color="gray.300">
                  El apoyo directo de la comunidad fomenta la experimentación y la toma de riesgos creativos, acelerando la innovación en el sector.
                </Text>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default IndustryImpactSection;
