import React from 'react';
import { Box, Heading, Text, Flex, Stack } from '@chakra-ui/react';
import mutualBenefitImage from '../../assets/images/Mutual.jpg'; 

const MutualBenefitSection: React.FC = () => {
  return (
    <Box w="100%" py="4em" px="2em" bg="black" color="white">
      <Box maxW="75em" mx="auto">
        <Flex direction={{ base: 'column', md: 'row' }} align="center" gap="2em">
          
          {/* Imagen a la izquierda */}
          <Box flex="1" textAlign="center">
            <img src={mutualBenefitImage} alt="Mutual Benefit" style={{ maxWidth: '100%', borderRadius: '0.9em' }} />
          </Box>
          
          {/* Textos numerados a la derecha */}
          <Box flex="1">
            <Heading as="h2" fontSize={{ base: '2em', md: '3em' }} mb="1em" color="white">
              Beneficios Mutuos: Una Relación Ganar-Ganar
            </Heading>

            <Stack spacing="2em" fontSize="1.2em" color="gray.300">
              <Box>
                <Heading as="h3" fontSize="1.5em" mb="0.5em">
                  1. Inversión Inicial
                </Heading>
                <Text>
                  La comunidad proporciona apoyo financiero y moral en las etapas tempranas de la carrera del talento.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" fontSize="1.5em" mb="0.5em">
                  2. Desarrollo y Crecimiento
                </Heading>
                <Text>
                  El talento se enfoca en su formación y mejora continua, respaldado por los recursos de la comunidad.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" fontSize="1.5em" mb="0.5em">
                  3. Éxito Profesional
                </Heading>
                <Text>
                  A medida que el talento alcanza el éxito, la comunidad comienza a recibir regalías por su trabajo o creaciones.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" fontSize="1.5em" mb="0.5em">
                  4. Reconocimiento y Expansión
                </Heading>
                <Text>
                  El éxito del talento se traduce en mayores beneficios para la comunidad y oportunidades de crecimiento mutuo.
                </Text>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default MutualBenefitSection;
