import React from 'react';
import { Box, Heading, Text, Flex, Stack } from '@chakra-ui/react';
import creativeProcessImage from '../../assets/images/CreativeProcess.jpg';

const CreativeProcessSection: React.FC = () => {
  return (
    <Box w="100%" py="4em" px="2em" bg="black" color="white">
      <Box maxW="75em" mx="auto">
        <Flex direction={{ base: 'column', md: 'row-reverse' }} align="center" gap="2em">
          
          {/* Imagen a la derecha */}
          <Box flex="1" textAlign="center">
            <img src={creativeProcessImage} alt="Creative Process" style={{ maxWidth: '100%', borderRadius: '0.9em' }} />
          </Box>
          
          {/* Textos numerados a la izquierda */}
          <Box flex="1">
            <Heading as="h2" fontSize={{ base: '2em', md: '3em' }} mb="1em" color="white">
              Participación Activa en el Proceso Creativo
            </Heading>

            <Stack spacing="2em" fontSize="1.2em" color="gray.300">
              <Box>
                <Heading as="h3" fontSize="1.5em" mb="0.5em">
                  1. Toma de Decisiones Colaborativa
                </Heading>
                <Text>
                  Los miembros de la comunidad pueden participar en decisiones importantes sobre la carrera del talento, aportando diversas perspectivas y experiencias.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" fontSize="1.5em" mb="0.5em">
                  2. Feedback Constructivo
                </Heading>
                <Text>
                  La comunidad ofrece retroalimentación valiosa sobre el trabajo del talento, contribuyendo a su mejora continua y adaptación al mercado.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" fontSize="1.5em" mb="0.5em">
                  3. Networking y Oportunidades
                </Heading>
                <Text>
                  Los miembros pueden abrir puertas y crear conexiones profesionales para el talento, ampliando su red y posibilidades de éxito.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" fontSize="1.5em" mb="0.5em">
                  4. Cocreación de Contenido
                </Heading>
                <Text>
                  En algunos casos, la comunidad puede colaborar directamente en proyectos creativos, generando contenido único y diverso.
                </Text>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default CreativeProcessSection;
