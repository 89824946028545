import React from 'react';
import { Box, Heading, Grid, useMediaQuery } from '@chakra-ui/react';
import ProjectCard from '../cards/ProjectCard';
import GradientButton from '../buttons/GradientButton';
import { Project } from '../../config/types';

interface ProjectsSectionProps {
  //activeProjects: Project[];
  upcomingProjects: Project[];
  studyProjects: Project[];
}

const ProjectsSection: React.FC<ProjectsSectionProps> = ({ /*activeProjects,*/ upcomingProjects, studyProjects }) => {
  // Usa useMediaQuery para aplicar estilos específicos para pantallas grandes
  const [isLaptopOrLarger] = useMediaQuery("(min-width: 992px)");

  return (
    <Box 
      w="100%" 
      py={10} 
      bgGradient="radial-gradient(circle at 0% 80%, #0ef1fa 0%, #0b4d78 100%)" // Background gradient
      color="gray.900" // Dark text color for better readability
    >
      <Box maxW="1200px" mx="auto" py={10} px={4} textAlign="center">
        <Heading 
          as="h2" 
          fontSize={isLaptopOrLarger ? "6xl" : "5xl"} 
          mb={isLaptopOrLarger ? 10 : 6} // Ajustar margen inferior según el tamaño de la pantalla
          color="white"
          paddingTop={isLaptopOrLarger ? "0" : "10px"} // Agregar espacio superior en pantallas más pequeñas
        >
          Proyectos Creados por miembros de nuestra comunidad
        </Heading>

       {/* <Heading as="h3" mb={4} fontSize="3xl" textAlign="left" color="white">Proyectos Activos</Heading>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
          {activeProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
          </Grid> */}

        <Heading as="h3" mb={4} fontSize="3xl" textAlign="left" color="white">Próximamente</Heading>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
          {upcomingProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </Grid>

         <Heading as="h3" mb={4} fontSize="3xl" textAlign="left" color="white">En Estudio</Heading>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
          {studyProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </Grid> 

         {/*Botón de Ver Más */}
        <Box textAlign="center" mt={10}>
          <GradientButton onClick={() => window.open('/projects', '_blank')}  gradientColor1="#0ef1fa"
              gradientColor2="#0b4d78">
            Ver Más
          </GradientButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectsSection;
