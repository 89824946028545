import React from 'react';
import { Box, Heading, Text, SimpleGrid, Image } from '@chakra-ui/react';
import GradientButton from '../buttons/GradientButton'; // Asegúrate de importar el botón

import sponsorLogo1 from '../../assets/images/bit2me.webp';
import sponsorLogo2 from '../../assets/images/atyum.png';
import sponsorLogo3 from '../../assets/images/cardador.svg';
import sponsorLogo4 from '../../assets/images/superpioneros.webp';

const SponsorsSection: React.FC = () => {
  const sponsors = [
    { 
      name: 'Bit2Me', 
      logo: sponsorLogo1, 
      invert: false, 
      url: 'https://bit2me.com/es/registro?prm=5RF100&utm_medium=desktop&utm_source=standard_referral&utm_campaign=5euros100&mkt_kind=referral&ref=AU3-POW-4HN' 
    },
    { 
      name: 'Atyum', 
      logo: sponsorLogo2, 
      invert: true, 
      url: 'https://atyum.com' 
    },
    { name: 'Cardador', logo: sponsorLogo3, invert: false, url: '#' },
    { name: 'SuperPioneros', logo: sponsorLogo4, invert: false, url: '#' },
  ];

  return (
    <Box 
      w="100%" 
      py={10} 
      px={{ base: 6, md: 8, lg: 10 }} 
      textAlign="center" 
      bg="black" // Fondo negro
      color="white" // Texto blanco
    >
      <Box maxW="1200px" mx="auto">
        <Heading 
          as="h2" 
          fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }} // Ajusta el tamaño del título según el tamaño de pantalla
          mb={4} 
          color="white"
        >
          Orgullosos de nuestros Partners
        </Heading>
        <Text fontSize={{ base: 'md', md: 'lg' }} mb={8}>
          En TokenMeet sabemos que trabajamos con profesionales del sector.
        </Text>
        <SimpleGrid columns={{ base: 2, sm: 3, md: 4 }} spacing={{ base: 5, md: 5 }}>
          {sponsors.map((sponsor, index) => (
            <Box 
              key={index} 
              p={4} 
              display="flex" 
              justifyContent="center" 
              alignItems="center"
              marginX="auto" // Centrar cada logo dentro de su contenedor
              maxW="180px"  // Limitar el ancho máximo del contenedor para evitar que los logos se junten demasiado
            >
              <a href={sponsor.url} target="_blank" rel="noopener noreferrer">
                <Image 
                  src={sponsor.logo} 
                  alt={sponsor.name} 
                  h="100px" 
                  maxW="100%" 
                  objectFit="contain" // Mantener proporciones originales
                  css={sponsor.invert ? { filter: 'invert(1)' } : {}} // Aplicar filtro de inversión si es necesario
                />
              </a>
            </Box>
          ))}
        </SimpleGrid>
        <Box mt={12} textAlign="center">
          <GradientButton onClick={() => window.location.href = '/proyectos'}  gradientColor1="#0ef1fa"
              gradientColor2="#0b4d78">
            Ver Proyectos
          </GradientButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SponsorsSection;
