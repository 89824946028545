import React from 'react';
import { Button, ButtonProps, useBreakpointValue } from '@chakra-ui/react';

interface GradientButtonProps extends ButtonProps {
  children: React.ReactNode;
  gradientColor1: string;
  gradientColor2: string;
}

const GradientButton: React.FC<GradientButtonProps> = ({ children, gradientColor1, gradientColor2, ...props }) => {
  // Usamos useBreakpointValue para ajustar el tamaño y la fuente según el tamaño de la pantalla
  const buttonSize = useBreakpointValue({ base: 'xl', md: 'md' });
  const fontSize = useBreakpointValue({ base: '1.2em', md: '1em' });

  return (
    <Button
      size={buttonSize}  // Tamaño dinámico del botón
      fontSize={fontSize}  // Tamaño dinámico de la fuente
      bgGradient={`radial-gradient(circle at 0% 80%, ${gradientColor1} 0%, ${gradientColor2} 100%)`}
      color="white"
      px={6}
      py={3}  // Ajustado para mejorar el espaciado
      border="none"
      borderRadius="99px"
      display="inline-flex" // Asegura que el botón se alinee correctamente en su contenedor
      alignItems="center"
      justifyContent="center"
      _hover={{
        bgGradient: `radial-gradient(circle at 0% 50%, ${gradientColor1} 0%, ${gradientColor2} 90%)`,
      }}
      _active={{
        bgGradient: `radial-gradient(circle at 0% 50%, ${gradientColor1} 0%, ${gradientColor2} 80%)`,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default GradientButton;
