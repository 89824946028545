import React from 'react';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';

const TechnologySection: React.FC = () => {
  return (
    <Box w="100%" py="4em" px="2em" bg="black" color="white" textAlign="center">
      <Box maxW="75em" mx="auto">
        {/* Título principal */}
        <Heading as="h2" fontSize={{ base: '2em', md: '3em' }} mb="1em" color="white">
          La Tecnología Detrás de TokenMeet
        </Heading>

        {/* Contenedor de las columnas */}
        <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center" gap="0em">
          
          {/* Columna 1 */}
          <Box flex="1" p="1em">
            <Heading as="h3" fontSize="1.8em" mb="1em">
              Blockchain Seguro
            </Heading>
            <Text fontSize="1.2em" color="gray.300">
              Utilizamos tecnología blockchain para garantizar la transparencia y seguridad de todas las transacciones y acuerdos.
            </Text>
          </Box>

          {/* Columna 2 */}
          <Box flex="1" p="1em">
            <Heading as="h3" fontSize="1.8em" mb="1em">
              Tokens Inteligentes
            </Heading>
            <Text fontSize="1.2em" color="gray.300">
              Nuestros tokens representan una participación real en el éxito del talento, con contratos inteligentes que aseguran una distribución justa de beneficios.
            </Text>
          </Box>

          {/* Columna 3 */}
          <Box flex="1" p="1em">
            <Heading as="h3" fontSize="1.8em" mb="1em">
              Plataforma Intuitiva
            </Heading>
            <Text fontSize="1.2em" color="gray.300">
              Una interfaz fácil de usar que permite a todos los usuarios, independientemente de su experiencia técnica, participar plenamente en el ecosistema.
            </Text>
          </Box>

          {/* Columna 4 */}
          <Box flex="1" p="1em">
            <Heading as="h3" fontSize="1.8em" mb="1em">
              Análisis Avanzado
            </Heading>
            <Text fontSize="1.2em" color="gray.300">
              Herramientas de análisis de datos que ayudan a la comunidad y al talento a tomar decisiones informadas sobre el desarrollo de la carrera.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default TechnologySection;
